import GeneralService from "@/common/general.service"
import * as uuidv1 from "uuid/v1"
import { slugify } from "@/common/general.service"
import _ from "lodash"

export default {
  data() {
    return {
      isWebOffline: false,
      lastKioskPong: null,
      pingHeartbit: null,
    }
  },
  methods: {
    playCurrentFile(event, item) {
      let self = this
      if (!self.playlistBlocked) {
        self.playlistBlocked = true
        const clickedElement = event.target
        clickedElement.closest(".item-rc").classList.add("pending-selected")
        self.playSpecificItem(item)
        self.playFileTimer = setTimeout(() => {
          self.playlistBlocked = false
          let element = document.querySelector(".item-rc")
          element.classList.remove("pending-selected")
        }, 3500)
      }
    },

    playNextFile() {
      let self = this
      if (!self.playlistBlocked) {
        self.playlistBlocked = true
        // Use when will need to preselect next item
        // let nextItem = GeneralService.next(document.querySelector(`#rc-playlist-block-${self.playlist.name} .item-rc.selected`))
        // if (nextItem.length > 0) {
        //   nextItem.classList.add('pending-selected')
        // } else {
        //   document.querySelector(`#rc-playlist-block-${self.playlist.name} .item-rc`).classList.add('pending-selected')
        // }
        self.nextItem()
        self.playFileTimer = setTimeout(() => {
          self.playlistBlocked = false
          document
            .querySelector(
              `#${this.idPlaylistBlock(self.playlist.name)} .item-rc`
            )
            .classList.remove("pending-selected")
        }, 3500)
      }
    },

    playPrevFile() {
      let self = this
      if (!self.playlistBlocked) {
        self.playlistBlocked = true
        let prevItem = GeneralService.prev(
          `#${this.idPlaylistBlock(self.playlist.name)} .item-rc.selected`
        )
        if (prevItem) prevItem.classList.add("pending-selected")
        self.prevItem()
        self.playFileTimer = setTimeout(() => {
          self.playlistBlocked = false
        }, 3500)
      }
    },

    getActiveFileName() {
      let self = this
      let activeGroupsList = self.playlist.groupsPlaylists[self.activeGroup]
      let activeFileIndex = self.playlist.index
      let findedActiveFile = _.find(activeGroupsList, function (o) {
        return o.pos == activeFileIndex
      })
      return _.isUndefined(findedActiveFile) ? "" : findedActiveFile.name
    },

    startItem() {
      this.initStartPauseRequest("Start")
    },

    pauseItem() {
      this.initStartPauseRequest("SetPause")
    },

    initStartPauseRequest(action) {
      let self = this
      if (self.websocket_common_conn) {
        // @todo must refactor to use only activePlaylist from store
        let activePl = self.playlist || self.activePlaylist
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.remote.control.playback",
            args: {
              action: action,
              project_key: self.rcEngineData.project,
              rc_key: self.rcEngineData._key,
              message: {
                type: action,
                playlist: activePl.name,
                pk: self.rcEngineData.pk,
                engine_name: self.rcEngineData.engine_name,
                project: self.rcEngineData.project,
              },
            },
          })
        )
      } else {
        console.log("WS connection not initialized and command not send")
      }

      // self.websocket_common_conn.send(JSON.stringify({
      // type: 'PlayListRC',
      //     message: {
      //         type: type,
      //         to: self.rcEngineData.peer_id,
      //         playlist: self.playlist.name,
      //         pk: self.rcEngineData.pk,
      //         engine_name: self.rcEngineData.engine_name,
      //         project: self.rcEngineData.project
      //     }
      // }))
    },

    playSpecificItem(item) {
      this.playItem(item.pos)
    },

    nextItem() {
      let nextPos = this.rcEngineData.haveGroups
        ? this.getGroupPrevPosition()
        : this.getSimpleNextPosition()
      this.playItem(nextPos)
    },

    prevItem() {
      let prevPos = this.rcEngineData.haveGroups
        ? this.getGroupPrevPosition()
        : this.getSimplePrevPosition()
      this.playItem(prevPos)
    },

    getActiveItem() {
      return parseInt(this.playlist.index)
    },

    getSimpleNextPosition() {
      return this.getActiveItem() + 1
    },

    getSimplePrevPosition() {
      let activeItem = this.getActiveItem()
      return activeItem == 0 ? -1 : activeItem - 1
    },

    getGroupNextPosition() {
      let self = this
      let activeItem = self.playlist.index
      let activeGroupsList = self.activeGroup
        ? self.playlist.groupsPlaylists[self.activeGroup]
        : null
      let isActiveItemInActiveGroup = _.find(activeGroupsList, function (o) {
        return o.pos == activeItem
      })
      let findedNext = activeGroupsList[0]["pos"]
      if (isActiveItemInActiveGroup) {
        let findedNextItem = _.find(activeGroupsList, function (o) {
          return o.pos > activeItem
        })
        if (!_.isUndefined(findedNextItem)) {
          findedNext = findedNextItem["pos"]
        }
      }
      return findedNext
    },

    getGroupPrevPosition() {
      let self = this
      let activeItem = self.playlist.index
      let activeGroupsList = self.activeGroup
        ? self.playlist.groupsPlaylists[self.activeGroup]
        : null
      let isActiveItemInActiveGroup = _.find(activeGroupsList, function (o) {
        return o.pos == activeItem
      })
      let findedPrev = activeGroupsList[0]["pos"]

      if (isActiveItemInActiveGroup) {
        let findedPrevItem = _.find(activeGroupsList, function (o) {
          return o.pos < activeItem
        })
        if (_.isUndefined(findedPrevItem)) {
          let lastItem = _.findLast(activeGroupsList)
          findedPrev = lastItem["pos"]
        } else {
          findedPrevItem = findedPrevItem["pos"]
        }
      }
      return findedPrev
    },

    playItem(pos) {
      let self = this
      if (self.websocket_common_conn) {
        // @todo must refactor to use only activePlaylist from store
        let activePl = self.playlist || self.activePlaylist
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.remote.control.playback",
            args: {
              action: "SetPosition",
              project_key: self.rcEngineData.project,
              rc_key: self.rcEngineData._key,
              message: {
                type: "SetPosition",
                playlist: activePl.name,
                pk: self.rcEngineData.pk,
                engine_name: self.rcEngineData.engine_name,
                project: self.rcEngineData.project,
                pos: pos,
              },
            },
          })
        )
      } else {
        console.log("WS connection not initialized and command not send")
      }
    },

    ping() {
      let self = this
      if (
        self.lastKioskPong !== null &&
        Date.now() - self.lastKioskPong >
          parseInt(process.env.VUE_APP_KIOSK_PING_TIMEOUT)
      ) {
        self.isWebOffline = true
      }
      self.websocket_common_conn.send(
        JSON.stringify({
          path: "1.0.kiosk.ping",
          args: {},
        })
      )
    },

    // @todo this is done special for event, need refactor later need to merge with playItem, playCurrentFile, playSpecificItem methods
    playItemEvent(pos, playlist, rcEngineData) {
      let self = this
      console.log(
        "playItemEvent",
        {
          type: "SetPosition",
          playlist: playlist.name,
          pk: rcEngineData._key,
          engine_name: rcEngineData.engine_name,
          project: rcEngineData.project,
          pos: pos,
        },
        rcEngineData
      )
      self.websocket_common_conn.send(
        JSON.stringify({
          path: "1.0.remote.control.playback",
          args: {
            action: "SetPosition",
            project_key: rcEngineData.project,
            rc_key: rcEngineData._key,
            message: {
              type: "SetPosition",
              playlist: playlist.name,
              pk: rcEngineData._key,
              engine_name: rcEngineData.engine_name,
              project: rcEngineData.project,
              pos: pos,
            },
          },
        })
      )
    },

    // @todo this is done special for event, need refactor later need to merge with similar functions
    startItemEvent(playlist, rcEngineData) {
      this.initStartPauseRequestEvent("Start", playlist, rcEngineData)
    },

    // @todo this is done special for event, need refactor later need to merge with similar functions
    pauseItemEvent(playlist, rcEngineData) {
      this.initStartPauseRequestEvent("SetPause", playlist, rcEngineData)
    },

    // @todo this is done special for event, need refactor later need to merge with similar functions
    initStartPauseRequestEvent(action, playlist, rcEngineData) {
      let self = this
      self.websocket_common_conn.send(
        JSON.stringify({
          path: "1.0.remote.control.playback",
          args: {
            action: action,
            project_key: rcEngineData.project,
            rc_key: rcEngineData._key,
            message: {
              type: action,
              playlist: playlist.name,
              pk: rcEngineData._key,
              engine_name: rcEngineData.engine_name,
              project: rcEngineData.project,
            },
          },
        })
      )
    },

    // @todo this is done special for event, need refactor later need to merge with similar functions
    setVolumePlaylistEvent(playlist, rcEngineData, volume, mute) {
      let self = this
      self.websocket_common_conn.send(
        JSON.stringify({
          path: "1.0.remote.control.playback",
          args: {
            action: "SetVolume",
            project_key: rcEngineData.project,
            rc_key: rcEngineData._key,
            message: {
              type: "SetVolume",
              playlist: playlist.name,
              pk: rcEngineData._key,
              engine_name: rcEngineData.engine_name,
              project: rcEngineData.project,
              volume: volume,
              mute: mute,
            },
          },
        })
      )
    },

    playCurrentFileDesktop(event, item) {
      let self = this
      if (!self.playlistBlocked) {
        self.playlistBlocked = true
        const clickedElement = event.target
        clickedElement.closest(".item-rc").classList.add("pending-selected")
        self.playSpecificItemDesktop(item)
        self.playFileTimer = setTimeout(() => {
          self.playlistBlocked = false
          let element = document.querySelector(".item-rc")
          element.classList.remove("pending-selected")
        }, 3500)
      }
    },

    playSpecificItemDesktop(item) {
      this.playItemDesktop(item.pos)
    },

    playItemDesktop(pos) {
      let self = this
      if (self.websocket_common_conn) {
        // @todo must refactor to use only activePlaylist from store
        let activePl = self.playlist || self.activePlaylist
        self.websocket_common_conn.send(
          JSON.stringify({
            cmd: "send_cmd",
            name: self.rcEngineData._engine.name,
            info: {
              type: "SetPosition",
              playlist: activePl.name,
              pos: pos,
            },
            cmd_id: uuidv1(),
          })
        )
      } else {
        console.log("WS connection not initialized and command not send")
      }
    },

    playNextFileDesktop() {
      let self = this
      if (!self.playlistBlocked) {
        self.playlistBlocked = true
        // Use when will need to preselect next item
        // let nextItem = GeneralService.next(document.querySelector(`#rc-playlist-block-${self.playlist.name} .item-rc.selected`))
        // if (nextItem.length > 0) {
        //   nextItem.classList.add('pending-selected')
        // } else {
        //   document.querySelector(`#rc-playlist-block-${self.playlist.name} .item-rc`).classList.add('pending-selected')
        // }
        self.nextItemDesktop()
        self.playFileTimer = setTimeout(() => {
          self.playlistBlocked = false
          document
            .querySelector(
              `#${this.idPlaylistBlock(self.playlist.name)} .item-rc`
            )
            .classList.remove("pending-selected")
        }, 3500)
      }
    },

    playPrevFileDesktop() {
      let self = this
      if (!self.playlistBlocked) {
        self.playlistBlocked = true
        console.log("playPrevFileDesktop", self.playlist)
        let prevItem = GeneralService.prev(
          `#${this.idPlaylistBlock(self.playlist.name)} .item-rc.selected`
        )
        if (prevItem) prevItem.classList.add("pending-selected")
        self.prevItemDesktop()
        self.playFileTimer = setTimeout(() => {
          self.playlistBlocked = false
        }, 3500)
      }
    },

    nextItemDesktop() {
      let nextPos = this.getSimpleNextPosition()
      this.playItemDesktop(nextPos)
    },

    prevItemDesktop() {
      let prevPos = this.getSimplePrevPosition()
      this.playItemDesktop(prevPos)
    },

    idPlaylistBlock(playlistName) {
      return `rc-playlist-block-${slugify(playlistName)}`
    },

    startItemDesktop() {
      this.initStartPauseRequestDesktop("Start")
    },

    pauseItemDesktop() {
      this.initStartPauseRequestDesktop("SetPause")
    },

    initStartPauseRequestDesktop(action) {
      let self = this
      if (self.websocket_common_conn) {
        // @todo must refactor to use only activePlaylist from store
        let activePl = self.playlist || self.activePlaylist
        self.websocket_common_conn.send(
          JSON.stringify({
            cmd: "send_cmd",
            name: self.rcEngineData._engine.name,
            info: {
              type: action,
              playlist: activePl.name,
            },
            cmd_id: uuidv1(),
          })
        )
      } else {
        console.log("WS connection not initialized and command not send")
      }
    },
  },
}
