<template>
  <div class="kiosk-terminal" data-testid="container-kiosk">
    <div class="grey lighten-3 kiosk-rogers-playlists-container fll-size-cont">
      <div class="bg-main-peoples full-w-background">
        <div class="bg-grey_shadow fll-size-cont full-w-background"></div>
        <div class="middle-content brder-red">
          <template v-if="isMaintanceMode">
            <v-card
              color="transparent"
              dark
              style="height: 100%; min-height: 100%; padding-top: 60%"
              class="d-flex flex-column"
              data-testid="container-system-maintenance"
            >
              <v-card-text class="flex">
                <v-container fill-height>
                  <v-layout align-center>
                    <v-flex text-xs-center>
                      <h4 class="display-3">System maintenance.</h4>
                      <p class="title">Please come back later.</p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
            <!-- <v-jumbotron color="transparent" dark style="height:100%; min-height: 100%; padding-top: 60%;">
                <v-container fill-height>
                  <v-layout align-center>
                    <v-flex text-xs-center>
                      <h4 class="display-3">
                        System maintenance.
                      </h4>
                      <p class="title">
                        Please come back later.
                      </p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-jumbotron> -->
          </template>

          <template v-else-if="playedSong">
            <v-layout
              row
              wrap
              class="transparent"
              data-testid="container-played-song"
            >
              <v-flex xs12 sm12 md12 text-center>
                <div
                  style="
                    position: relative;
                    width: 100%;
                    margin: 0 auto 6em auto;
                    height: 28em;
                  "
                >
                  <div class="np-img"></div>
                  <!--                      <img src="../../../../../assets/img/rogers-kiosk/NowPlaying.png" class="img-now-playing"/>-->
                </div>
                <div
                  class="filename-now-playing"
                  data-testid="title-played-song"
                >
                  {{ playedSong.name }}
                  <br />
                  <span class="by-tyl">by Tyler Shaw</span>
                </div>
                <v-btn
                  class="main-btn-rogers btn-end"
                  style="display: none"
                  @click="endConcert()"
                  data-testid="btn-end-concert"
                >
                  End Concert
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <template v-else>
            <template v-if="step === 'intro'">
              <v-layout
                row
                wrap
                class="playlists-container pt-5 transparent"
                data-testid="container-intro"
              >
                <v-flex xs12 sm12 md12 text-center>
                  <!--                    <div>-->
                  <!--                      <img src="../../../../../assets/img/rogers-kiosk/MKTG_RogersFlagship_title_intro.png" class="img-logo"/>-->
                  <!--                    </div>-->
                  <div
                    class="white--text pt-5 pb-3 welcome-txt"
                    style="margin-top: 12em"
                  >
                    Welcome!
                  </div>
                  <v-btn
                    class="btn-begin main-btn-rogers mt-4"
                    @click="start()"
                    data-testid="btn-start"
                  >
                    Start!
                  </v-btn>
                </v-flex>
              </v-layout>
            </template>

            <template v-if="step === 'thanx'">
              <v-layout
                row
                wrap
                class="playlists-container pt-5 transparent thanx-container"
                style="margin-top: 10em"
                data-testid="container-thanx"
              >
                <v-flex xs12 sm12 md12 text-center>
                  <!--                    <div>-->
                  <!--                      <img src="../../../../../assets/img/rogers-kiosk/MKTG_RogersFlagship_title_intro.png"-->
                  <!--                           class="img-logo"/>-->
                  <!--                    </div>-->

                  <div class="first-text white--text pt-3 pb-3">
                    Rogers 5G will
                    <span class="red-t">revolutionize</span>
                    the way we experience music.
                  </div>

                  <div class="second-text white--text pt-3 pb-3">
                    What’s impossible today, won’t be tomorrow.
                  </div>
                </v-flex>
              </v-layout>
            </template>
            <template v-if="step === 'playlists'">
              <v-layout
                row
                wrap
                class="playlists-container transparent"
                data-testid="container-playlists"
              >
                <v-flex xs12 sm12 md12 class="text-center">
                  <div class="select-tyler-txt">
                    Select a <span>Tyler Shaw</span> song <br />
                    below to start the set!
                  </div>
                  <v-layout row wrap>
                    <v-btn-toggle
                      v-model="toggle_multiple"
                      color="primary"
                      dense
                      group
                      v-if="haveHashInfo()"
                      xs6
                      sm6
                      md6
                      class="files-list"
                    >
                      <v-layout row wrap v-if="getAllFiles">
                        <v-flex
                          v-for="(item, key) in getAllFiles"
                          :class="{
                            'right-elm': key % 2 == 0,
                            'left-elm': key % 2 != 0,
                            'offset-xs3 offset-sm3 offset-md3': !haveNext(key),
                          }"
                          xs6
                          sm6
                          md6
                          :key="key"
                          style="margin-bottom: 50px"
                        >
                          <v-btn
                            block
                            class="btn-file"
                            :value="item.pos"
                            style="word-wrap: normal"
                            data-testid="btn-play-item"
                          >
                            <span style="white-space: normal; max-width: 100%">
                              {{ item.name }}
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-btn-toggle>
                  </v-layout>
                </v-flex>

                <v-flex xs12 sm12 md12 text-center>
                  <!-- class="text-center" -->
                  <!-- {{toggle_multiple || 'No multiple toggle'}}
                    {{songToPlay || 'No song to play'}}
                    {{getAllFiles || 'No files on getAllFiles'}} -->
                  <v-btn
                    :disabled="button.playConcert"
                    large
                    color="white"
                    class="main-btn-rogers play-concert-btn"
                    @click="playConcert()"
                    data-testid="btn-play-concert"
                  >
                    Play Concert!
                  </v-btn>
                </v-flex>
              </v-layout>
            </template>
          </template>
          <v-btn
            class="btn-refresh"
            @click="refreshButton()"
            data-testid="btn-refresh"
          ></v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import WsConnMixin from "@/mixins/websocket-connection.js"
import RCPlaylistsMixin from "@/mixins/remote-control/rc-playlists"
import _ from "lodash"

export default {
  mixins: [WsConnMixin, RCPlaylistsMixin],
  data() {
    return {
      input: "",
      rcHashInfo: null,
      showForm: false,
      showPlaylist: false,
      survey: {
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Address: "",
        Age: "",
        Language: "",
        ExistingCustomer: "",
        CASL: "",
        Vendor: "",
        Activation: "",
        PostalCode: "",
      },
      loader: {
        submit: false,
      },
      form: [],
      inputName: "first_name",
      languages: ["EN", "FR"],
      playlist: null,
      step: "playlists",
      stepsOptions: ["intro", "form", "rules", "playlists", "error"],
      playingStarted: false,
      // playingFile: null,
      // playingGroup: null,
      playingFileObj: null,
      socketInitialized: false,
      timeoutObj: null,
      toggle_multiple: null,
      block: false,
      playConcertClicked: false,
      websocketReadyStatus: 1, // need refactor check websocket connection status without this param
      goMainTimeout: 30,
      timeoutEndSessionObj: null,
      goMainEndSessionTimeout: 30,
      button: {
        playConcert: false,
      },
    }
  },

  created() {
    let self = this
    document.getElementsByTagName("meta")["viewport"].content =
      "min-width: 980px;"
    self.$store
      .dispatch("rcPlaylistsKioskHashInfoAction", self.$route.params.link_hash)
      .then((resp) => {
        self.rcHashInfo = resp.data.data
        self.$store
          .dispatch(
            "rcSaveActivePlaylistKeyAction",
            self.rcHashInfo.kiosk.playlist_index
          )
          .then((resp) => {})
        self.initApp()
        self.pingHeartbit = setInterval(() => {
          self.ping()
        }, parseInt(process.env.VUE_APP_KIOSK_PING_INTERVAL))
      })
      .catch((err) => {
        // console.log(err)
      })
    window.addEventListener("offline", (event) => {
      clearInterval(self.pingHeartbit)
      self.isWebOffline = true
    })
    window.addEventListener("online", (event) => {
      self.isWebOffline = false
      self.pingHeartbit = setInterval(() => {
        self.ping()
      }, parseInt(process.env.VUE_APP_KIOSK_PING_INTERVAL))
    })
  },

  mounted() {
    document.querySelector("html").style.overflow = "hidden"
  },

  watch: {
    goMainTimeout: {
      handler: function (value) {
        let self = this
        if (value <= 0) {
          self.initApp()
          self.step = "intro"
          self.stopTimerGoMain()
          self.playSpecificItem({ pos: 0 })
        }
      },
      deep: true,
    },
    goMainEndSessionTimeout: {
      handler: function (value) {
        let self = this
        if (value <= 0) {
          document.querySelectorAll(".btn-end").forEach(function (el) {
            el.style.display = "inline-flex"
          })
          self.stopTimerEndSession()
        }
      },
      deep: true,
    },
    rcEngineData: function (newData, oldData) {
      let self = this
      let aspPlayedFile = parseInt(self.activePlaylist.index)
      if (aspPlayedFile == 0 && !self.playedSong) {
        self.step = "playlists"
        self.toggle_multiple = null
      } else if (aspPlayedFile == 3) {
        self.toggle_multiple = null
        self.step = "playlists"
      }
    },
    step: function (newData, oldData) {
      let self = this
      if (newData == "playlists") {
        // self.startTimerGoMain();
      } else {
        setTimeout(function () {
          document.querySelectorAll(".btn-end").forEach(function (el) {
            el.style.display = "none"
          })
        }, 1000)
        self.stopTimerGoMain()
      }
      document
        .querySelectorAll(".btn-file .v-btn__content")
        .forEach(function (el) {
          el.style.whiteSpace = "pre-wrap"
        })
    },
    isWebOffline: function (newData, oldData) {
      let self = this
      if (!newData) {
        self.stopTimerGoMain()
      }
      self.initApp()
      self.getEngineParsedInfo()
    },
    toggle_multiple: function (newData, oldData) {
      let self = this
      //self.restartTimerGoMain();
    },
  },

  computed: {
    ...mapGetters({
      rcEngineData: "getRcEngineData",
      websocket_common_conn: "getWebsocketCommonConnection",
      rogersConcertPlaylist: "getRogersConcertPlaylist",
      rogersActiveFile: "getRogersActiveFile",
      rogersActiveFileKey: "getRogersActiveFileKey",
      activePlaylist: "getActivePlaylist",
    }),
    getAllFiles() {
      let finalFiles = null
      let groupsData = _.get(this.rcEngineData, ["items", "0", "groupsData"])
      if (!_.isEmpty(groupsData)) {
        let groupSongs = _.find(groupsData, { group: "Songs" })
        if (!_.isEmpty(groupSongs)) {
          finalFiles = groupSongs.items
        }
      }
      return finalFiles
    },
    songToPlay() {
      return _.find(this.getAllFiles, { pos: this.toggle_multiple })
    },
    playedSong() {
      let self = this
      let playedFileIndex = _.get(self.activePlaylist, "index")
      return !playedFileIndex
        ? null
        : _.find(self.getAllFiles, (o) => o.pos == playedFileIndex)
    },
    isMaintanceMode() {
      return (
        this.isWebOffline ||
        !this.rcEngineData.is_active ||
        !this.rcEngineData._engine.is_online
      )
    },
  },

  methods: {
    refreshButton() {
      location.reload(true)
    },
    initApp() {
      let self = this
      self.getEngineParsedInfo().then(function () {
        //self.loading = false;
        self.getNowPlayingFileName()
      })
      if (!self.socketInitialized) {
        self.initWebsocketConnection()
      }
      if (!self.step) {
        self.step = "playlists"
      }
    },

    haveNext(key) {
      if (key % 2 == 0) {
        let findedNext = _.get(this.getAllFiles, [key + 1])
        if (!findedNext) {
          return false
        }
      }
      return true
    },
    start() {
      let self = this
      document.querySelector(".btn-begin").classList.add("active")
      self.step = "playlists"
      self.playSpecificItem({ pos: 2 })
      setTimeout(function () {
        document.querySelector(".btn-begin").classList.remove("active")
      }, 500)
    },
    endConcert() {
      let self = this
      document.querySelector(".btn-end").classList.add("active")
      setTimeout(function () {
        self.playSpecificItem({ pos: 3 })
        self.step = "playlists"
        document.querySelector(".btn-end").classList.remove("active")
      }, 800)
    },
    goToPlaylistsStep() {
      let self = this
      self.step = "playlists"
    },
    //Intro Timer
    startTimerGoMain() {
      let self = this
      if (self.step !== "intro") {
        self.timeoutObj = setInterval(() => {
          self.goMainTimeout--
        }, 1000)
      }
    },

    stopTimerGoMain() {
      clearInterval(this.timeoutObj)
      this.goMainTimeout = 30
    },

    restartTimerGoMain() {
      this.stopTimerGoMain()
      this.startTimerGoMain()
    },
    //End Intro Timer

    //End Session Timer
    startTimerEndSession() {
      let self = this
      if (self.step !== "intro") {
        self.timeoutEndSessionObj = setInterval(() => {
          self.goMainEndSessionTimeout--
        }, 1000)
      }
    },

    stopTimerEndSession() {
      clearInterval(this.timeoutEndSessionObj)
      this.goMainEndSessionTimeout = 30
    },
    //End Session Timer
    haveHashInfo() {
      return !_.isNull(this.rcHashInfo)
    },
    getNowPlayingFileName() {
      let self = this
      self.playingFileObj =
        !self.playlist ||
        _.isUndefined(self.playlist) ||
        self.playlist.index == -1 ||
        self.playlist.index == 0
          ? null
          : _.find(
              self.playlist.parsedItems,
              (o) => o.pos == self.playlist.index
            )
      return self.playingFileObj
    },
    getEngineParsedInfo() {
      let self = this
      let rcKey = _.get(self.rcHashInfo, ["kiosk", "rc_engine_key"])
      if (rcKey) {
        let data = {
          slug: rcKey,
        }
        return this.$store
          .dispatch("rcPlaylistsEngineParsedInfoAction", data)
          .then(function (result) {})
      } else {
        alert("cannot")
      }
    },
    initWebsocketConnection() {
      let self = this
      self.socketInitialized = true
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          if (_.get(data, "path", "") === "1.0.ev.refresh.page") {
            self.initApp()
          } else if (_.get(data, "path", "") === "1.0.kiosk.ping") {
            self.lastKioskPong = Date.now()
            if (self.isWebOffline === true) {
              self.isWebOffline = false
            }
          }
        }
      }
      self.websocket_common_conn.onopen = function (e) {
        self.isWebOffline = false
        self.websocketReadyStatus = self.websocket_common_conn.OPEN
        self.step = "playlists"
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.kiosk.mark.connection",
            args: {
              kiosk_key: self.$route.params.link_hash,
            },
          })
        )
      }
      self.websocket_common_conn.onclose = function (e) {
        console.log("General socket connection closed unexpectedly")
        self.isWebOffline = true
        self.websocketReadyStatus = self.websocket_common_conn.CLOSED
      }
    },
    playSpecificItem(item) {
      let self = this
      if (item) {
        self.playItem(item.pos)
      }
    },
    playConcert() {
      let self = this
      self.button.playConcert = true
      document.querySelector(".play-concert-btn").classList.add("active")
      setTimeout(function () {
        if (self.toggle_multiple && !self.playConcertClicked) {
          self.playSpecificItem(self.songToPlay)
          self.playConcertClicked = true
          setTimeout(function () {
            self.playConcertClicked = false
            document
              .querySelector(".play-concert-btn")
              .classList.remove("active")
          }, 500)
        } else {
          document.querySelector(".play-concert-btn").classList.remove("active")
        }
        let btnFile = document.querySelector(".btn-file")
        if (btnFile) btnFile.classList.remove("v-btn--active")
      }, 500)
      setTimeout(function () {
        self.button.playConcert = false
      }, 10000)
      //self.stopTimerGoMain();
      self.startTimerEndSession()
    },
  },
}
</script>
<style lang="sass">
@media (min-width : 799px) and (min-height : 1919px) and (max-width : 1081px) and (max-height : 1921px)
  body, html
    overflow: hidden !important
    background-color: red !important
  .kiosk-terminal
    overflow: hidden

  .img-now-playing
    height: 600px
    position: absolute
    left: -7% !important
    top: -4% !important
    margin-top: 0px !important
</style>

<style lang="sass" scoped>
@font-face
  font-family: "TedNext-Semibold"
  src: url("../../../../../assets/fonts/rogers-kiosk/TedNext-Semibold.ttf") format("truetype")

@font-face
  font-family: "TedNext-Bold"
  src: url("../../../../../assets/fonts/rogers-kiosk/TedNext-Bold.ttf") format("truetype")

@font-face
  font-family: "TedNext-Regular"
  src: url("../../../../../assets/fonts/rogers-kiosk/TedNext-Regular.ttf") format("truetype")

.kiosk-terminal
  width: 100%
  height: 100vh
  background-color: #9d9d9d

  .np-img
    position: relative
    background-image: url('../../../../../assets/img/rogers-kiosk/NowPlaying.png')
    width: 70%
    height: 100%
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    margin: 0 auto
    padding-bottom: 5em

  input
    width: 100%
    height: 100px
    padding: 20px
    font-size: 20px
    border: none
    box-sizing: border-box

  .simple-keyboard
    margin: 0 auto
    max-width: 850px

  .fll-size-cont
    height: 1920px
    min-height: 100%
    width: 100%
    min-width: 100%
    position: absolute

  .kiosk-rogers-playlists-container
    position: relative

  .full-w-background
    height: 100%
    background-position: center
    background-repeat: no-repeat
    background-size: cover

  .bg-main-peoples
    background-image: url("../../../../../assets/img/rogers-kiosk/vishnu-r-nair-m1WZS5ye404-unsplash_1920x1080.png")

  .bg-grey_shadow
    background-image: url("../../../../../assets/img/rogers-kiosk/MKTG_bg_grey_chadow.png")

  .middle-content
    min-width: 100%
    min-height: 100%
    z-index: 200
    position: absolute

  .select-tyler-txt
    font-family: "TedNext-Semibold"
    padding-top: 2.4em
    padding-bottom: 2em
    text-align: center
    color: #fff
    font-size: 3.0em // font-size: 3.2em
    line-height: 1.2em

    span
      color: #da291c

  .playlists-container
    background-color: transparent
    margin: 0 auto
    height: 100%
    height: -moz-available
    height: -webkit-fill-available
    height: stretch
    /*padding-bottom: 50px

  .btn-file
    text-transform: none
    width: 100%
    line-height: 72px
    border-radius: 10px
    display: block
    white-space: normal
    > .v-btn__content
      white-space: normal !important
      max-width: 100% !important

  /*.v-btn-toggle .btn-file.v-btn*/
  /*  opacity: 0.55 !important*/
  /*  background-color: #37474F !important*/
  /*  border-color: #37474F !important*/

  .right-elm
    padding-right: 15px

  .left-elm
    padding-left: 15px

  .img-logo
    height: 860px
    padding-top: 210px
    padding-bottom: 50px

  .img-now-playing
    height: 600px
    /*position: absolute
    margin-top: -67px
    /*right: 5%
    /*top: -11%

  .filename-now-playing
    font-family: "TedNext-Semibold"
    font-size: 3.6em
    color: #fff
    width: 82%
    margin: 0 auto
    .by-tyl
      font-family: "TedNext-Regular"

  .main-btn-rogers
    background-color: #DB2B1C !important
    font-family: "TedNext-Bold" !important
    width: 52% !important
    height: auto !important
    font-size: 2.4em !important // font-size: 2.8em !important
    line-height: 1.8em
    color: #fff !important
    text-transform: none
    text-align: center
    border-radius: 30px
    padding: 16px 0
    .v-btn--active
      background-color: #fff !important
      -webkit-box-shadow: 3px 3px 22px 10px rgba(209, 17, 11, 1)
      -moz-box-shadow: 3px 3px 22px 10px rgba(209, 17, 11, 1)
      box-shadow: 3px 3px 22px 10px rgba(209, 17, 11, 1)
    .v-btn__content
      justify-content: center !important
    .v-btn__content
      white-space: pre-wrap
      // padding: 20px 0

  .main-btn-rogers.active
    background-color: #fff !important
    color: #030303 !important
    -webkit-box-shadow: 0px 0px 30px 10px rgba(209, 17, 11, 1)
    -moz-box-shadow: 0px 0px 30px 10px rgba(209, 17, 11, 1)
    box-shadow: 0px 0px 50px 20px rgba(209, 17, 11, .9)

  .link-preview
    color: #BDBDBD
    font-size: 30px

  .btn-begin
    font-size: 42px

  .btn-end
    font-size: 42px
    margin-top: 5em !important

  /*.play-concert-btn*/
  /*  font-size: 46px*/
  /*  margin-top: 80px*/
  /*  border-radius: 10px*/
  /*  -webkit-box-shadow: 3px 3px 22px 10px rgba(209, 17, 11, 1)*/
  /*  -moz-box-shadow: 3px 3px 22px 10px rgba(209, 17, 11, 1)*/
  /*  box-shadow: 3px 3px 22px 10px rgba(209, 17, 11, 1)*/
  /*  width: auto*/


  /*position: absolute*/
  /*bottom: 30%*/
  /*right: 30%*/

  .brder-red
    border: 5em solid transparent
    border-image-source: url('../../../../../assets/img/rogers-kiosk/Frame_002.png')
    border-image-slice: 200

  .files-list
    background-color: transparent
    width: 86%
    margin: 0 auto
    margin-bottom: 5em

  .files-list.v-btn-toggle
    .btn-file.v-btn
      font-size: 1.7em !important // font-size: 2.3em !important
      font-family: "TedNext-Bold" !important
      /*display: block
      background-color: #414042
      opacity: 1 !important
      line-height: normal
      /*white-space: normal !important
      color: #fff !important
      height: max-content
      min-height: 2.9em
      /*.v-btn__content*/
      /*  white-space: normal !important*/

    .btn-file.v-btn.v-btn--active
      background-color: #DB2B1C !important
      border: 5px solid #E19481 !important
      -webkit-box-shadow: 0px 0px 30px 10px rgba(209, 17, 11, 1)
      -moz-box-shadow: 0px 0px 30px 10px rgba(209, 17, 11, 1)
      box-shadow: 0px 0px 30px 10px rgba(209, 17, 11, 1)

  .files-list.v-btn-toggle.v-btn-toggle--selected
      box-shadow: none !important
      -webkit-box-shadow: none !important

  .rogers-active-playlist
    color: #fff
    background-color: #4D0E08
    margin-top: 100px
    /*background-color: rgba(77, 14, 8, .3)
    opacity: 0.8
    border-radius: 10px
    padding: 10px

    .v-list__tile
      height: 68px !important

    .v-subheader
      justify-content: center
      opacity: 0.9
      color: #fff
      font-size: 35px
      line-height: 40px
      text-align: center

    .v-list__tile__content
      font-size: 33px
      line-height: 40px

    .v-list__tile__action .v-icon
      font-size: 50px

  .welcome-txt
    font-family: "TedNext-Semibold"
    font-size: 5.1em
    width: 80%
    margin: 0 auto
    padding-top: 300px

.thanx-container
  .first-text,
  .second-text
    font-family: "TedNext-Semibold"
    font-size: 3.6em
    width: 84%
    margin: 0 auto
  .red-t
    font-family: "TedNext-Bold"
    color: #da291c
  .second-text
    padding-top: 2em
.btn-refresh
  position: absolute
  bottom: 0
  right: 0
  background-color: transparent!important
  cursor: default
  box-shadow: none!important
</style>

<style lang="sass">
@media (min-width : 1079px) and (min-height : 1919px) and (max-width : 1081px) and (max-height : 1921px)
  body, html
    overflow: hidden !important
    background-color: red !important
  .kiosk-terminal
    overflow: hidden
    .img-now-playing
      height: 600px
      position: absolute
      left: -7% !important
      top: -4% !important
      margin-top: 0px !important

.kiosk-terminal
  overflow: hidden

  .fll-size-cont
    height: 100vh !important
    min-height: 100%
    width: 100%
    min-width: 100%
    position: absolute

  .np-img
    left: -10%
    width: 125% !important
    height: 90%

  .img-logo
    height: 50vh
    padding-top: 10%
    padding-bottom: 50px

  .files-list.v-btn-toggle
    .btn-file.v-btn
      font-size: 1.7em !important

  .filename-now-playing
    font-size: 4.2em !important

  .welcome-txt
    font-size: 4em !important

  .brder-red
    border-image-slice: 250 !important


@media (min-width : 767px)
  .kiosk-terminal
    overflow: auto !important
    .fll-size-cont
        height: 100% !important
</style>
